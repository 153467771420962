<template>

<div class="input" :data-type="type">

	<div class="input-label" v-html="label" />

    <input v-bind:value="value" type="text" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" class="input-control" v-if="type === 'textbox'" />

    <textarea v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" class="input-control" v-if="type === 'textarea'" />

    <input v-bind:value="value" v-on:input="$emit('input', !value)" type="checkbox" class="input-control" v-if="type === 'checkbox'" />

</div>

</template>

<script>

export default {

	name: 'ComInput',

    props: ['label', 'value', 'placeholder', 'type']

}

</script>

<style scoped>

.input {
    margin-bottom: 20px;
}

.input-label {
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: light;
    color: #fff;
    text-transform: uppercase;
    padding-left: 10px;
    margin-bottom: 10px;
}

.input-control[type="text"] {
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    line-height: 42px;
    padding: 0px 20px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1.2px;
    width: 100%;
}

textarea.input-control {
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    line-height: 16px;
    height: 200px;
    padding: 20px 20px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1.2px;
    width: 100%;
}

.input-control::placeholder {
    color: #fff;
}

.input[data-type="checkbox"] {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: 10px;
    line-height: 18px;
}

.input-control[type="checkbox"] {
    width: 18px;
    height: 18px;
    border: 1px solid #707070;
    background-color: transparent;
}

.input-label >>> a {
    color: #FDAA2A;
    font-weight: 500;
}

.input-label >>> a:hover {
    text-decoration: underline;
}

</style>
