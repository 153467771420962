<template>

<div ref="map" class="map" v-hammer:pan="onMapPan" v-hammer:pinch="onMapPinch">

	<v-stage ref="stage" :config="config" v-on:wheel="onMouseWheel">

		<com-background />

		<v-layer :config="layerConfig">

			<com-route v-for="route in routes" :key="route.id" :route="route" />

		</v-layer>

		<v-layer :config="layerConfig">

			<com-dot v-for="(dot, index) in dots" :key="index" :dot="dot" />

		</v-layer>

		<v-layer :config="layerConfig">

			<com-port v-for="(port, index) in ports" :key="index" :port="port" />

		</v-layer>

	</v-stage>

</div>

</template>

<script>

import Hammer from 'hammerjs'

import comBackground from './map/Background'
import comRoute from './map/Route'
import comPort from './map/Port'
import comDot from './map/Dot'

import mixMap from '@/mixin/map'
import mixPan from '@/mixin/map/pan'
import mixZoom from '@/mixin/map/zoom'

export default {

	name: 'ComMap',

	mixins: [mixMap, mixPan, mixZoom],

	components: {
		'com-background': comBackground,
		'com-route': comRoute,
		'com-port': comPort,
		'com-dot': comDot
	},

	data: function() {
	
		return {
		
			config: {
				width: this.$constants.map.width,
				height: this.$constants.map.height
			},
			
			layerConfig: {
				x: this.$constants.map.offsetWidth,
				y: this.$constants.map.offsetHeight
			},

			pinchJustEnded: false
		
		}
	
	},

	created: function() {

		this.$nextTick(function() {
		
			this.hammer = new Hammer.Manager(this.$refs.map, {
				domEvents: true
			})

			this.hammer.add(new Hammer.Tap({
				event: 'doubletap',
				taps: 2
			}))

			this.hammer.on('doubletap', this.onDoubleClick.bind(this))

			this.$_.delay(function() {
			
				this.panTo({
					duration: 0,
					x: this.$constants.center.x,
					y: this.$constants.center.y + this.$constants.map.offsetWidth
				})

			}.bind(this), 250)
		
		}.bind(this))

	},

	computed: {
	
		routes: function() {

			if(this.$store.getters['map/mode'] === 'multi') {

				return this.$store.getters['routes']
			
			} else {

				return this.$_.where(this.$store.getters['routes'], {
					type: 'primary'
				})
			
			}
		
		},
	
		ports: function() {

			return this.$store.getters['locations/ports']
		
		},
	
		dots: function() {

			return (this.$store.getters['map/mode'] === 'multi') ? this.$store.getters['locations/dots'] : []
		
		},

		selectedPort: function() {
		
			return this.$store.getters['map/selected']
		
		},

		selectedFrom: function() {
		
			return this.$store.getters['finder/from']
		
		},

		selectedTo: function() {
		
			return this.$store.getters['finder/to']
		
		},

		selectedPath: function() {
		
			return this.selectedFrom.toString() + '-' + this.selectedTo.toString()

		}

	},

	watch: {
	
		selectedPath: function() {

			this.focusOnFinder()
		
		},
	
		selectedPort: function(n) {

			if(n) {
			
				this.focusOnPort()
			
			} else if(!this.selectedFrom && !this.selectedTo) {
			
				this.zoomTo(this.$constants.scaling.max - this.$constants.scaling.step)
			
			}
		
		}

	},

	methods: {

		focusOnFinder: function() {

			if (this.selectedFrom && !this.selectedTo) {

				console.log('map/focusOnFinder/from')
			
				this.panTo({
					scale: this.$constants.scaling.min,
					x: this.$store.getters['locations/from'].x + this.$constants.map.offsetWidth - 300,
					y: this.$store.getters['locations/from'].y + this.$constants.map.offsetHeight
				})
			
			} else if (this.selectedTo && !this.selectedFrom) {

				console.log('map/focusOnFinder/to')
		
				this.panTo({
					scale: this.$constants.scaling.min,
					x: this.$store.getters['locations/to'].x + this.$constants.map.offsetWidth - 300,
					y: this.$store.getters['locations/to'].y + this.$constants.map.offsetHeight
				})
			
			} else if (this.selectedTo && this.selectedFrom) {

				console.log('map/focusOnFinder/fromTo')

				this.panTo({
					scale: this.$constants.scaling.min,
					x: ((this.$store.getters['locations/from'].x + this.$store.getters['locations/to'].x) / 2) + this.$constants.map.offsetWidth - 300,
					y: ((this.$store.getters['locations/from'].y + this.$store.getters['locations/to'].y) / 2) + this.$constants.map.offsetHeight,
				})
			
			} else {

				console.log('map/focusOnFinder/center')
			
				this.panTo({
					scale: this.$constants.scaling.min,
					x: this.$constants.center.x + this.$constants.map.offsetWidth,
					y: this.$constants.center.y + this.$constants.map.offsetHeight
				})

			}

		},

		focusOnPort: function() {

			console.log('map/focusOnPort')
		
			this.panTo({
				scale: this.$constants.scaling.max,
				x: this.$store.getters['locations/selected'].x + this.$constants.map.offsetWidth,
				y: this.$store.getters['locations/selected'].y + this.$constants.map.offsetHeight
			})

		},

		onDoubleClick: function(e) {

			if (!this.selectedPort) this.zoomByMouse(e)
		
		},
	
		onMouseWheel: function(e) {

			if (!this.selectedPort) this.zoomByMouse(e.evt)

		},

		onMapPinch: function(e) {

			this.pinchJustEnded = true

			this.$_.delay(function() {
			
				this.pinchJustEnded = false
			
			}.bind(this), 250)
		
			if (!this.selectedPort) this.zoomByPinch(e)

		},

		onMapPan: function(e) {

			if (!this.selectedPort && !this.pinchJustEnded) this.panByMouse(e)

		}
	
	}

}

</script>

<style scoped>

.map {
	position: absolute;
	left: 200px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
	z-index: 1;
}

.is-small .map {
	left: 100px;
}

</style>
