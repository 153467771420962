<template>

<div class="sidebar" :class="{'is-expanded': isExpanded}">

	<div class="sidebar-logo" />

    <com-menu v-if="currentSection === 'menu' || currentSection === 'routes'" v-on:change="onSectionChange($event)" />
    <com-ports v-if="currentSection === 'ports' || currentSection === 'finder.to' || currentSection === 'finder.from'" />
    <com-finder v-if="currentSection === 'finder'" />
    <com-contact v-if="currentSection === 'contact'" />
    <com-port v-if="currentSection === 'info'" v-on:back="onSectionChange('menu')" />

</div>

</template>

<script>

import comMenu from './sidebar/Menu'
import comPorts from './sidebar/Ports'
import comFinder from './sidebar/Finder'
import comPort from './sidebar/Port'
import comContact from './sidebar/Contact'

export default {

	name: 'ComSidebar',

    components: {
        'com-menu': comMenu,
        'com-ports': comPorts,
        'com-finder': comFinder,
        'com-port': comPort,
        'com-contact': comContact
    },

    computed: {

        currentSection: function() {
        
            return this.$store.getters['ui/sidebar']
        
        },
    
        isExpanded: function() {
        
            return this.currentSection === 'info' || this.currentSection === 'ports' || this.currentSection === 'finder' || this.currentSection === 'finder.to' || this.currentSection === 'finder.from' || this.currentSection === 'contact'
        
        }
    
    },

    methods: {
    
        onSectionChange: function(value) {
        
            this.$store.commit('ui/sidebar', value)
        
        }
    
    }

}

</script>

<style scoped>

.sidebar {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 300px;
	bottom: 0px;
	overflow: hidden;
    z-index: 3;
    background-color: #183353;
    padding: 56px 0px;
    transition: width 200ms ease-out;
    overflow-y: auto;
}

.sidebar.is-expanded {
    width: 580px;
}

.is-small .sidebar.is-expanded {
    width: 440px;
}

.sidebar-logo {
    width: 100%;
    height: 23px;
    background-image: url('~@/assets/logo.png');
    background-size: contain;
    background-position: 56px 50%;
    background-repeat: no-repeat;
    margin-bottom: 56px;
}

</style>
