<template>

<app-group :config="group" v-on:click="onClick" v-on:mouseenter="onMouseEnter" v-on:mouseleave="onMouseLeave">

	<v-rect ref="rect" :config="border" />

	<app-text :config="text" :fixed="true" anchor="leftcenter" />

</app-group>

</template>

<script>

import mixMap from '@/mixin/map'

export default {

    name: 'MapPortButton',

    mixins: [mixMap],

    props: ['config'],

    data: function() {
    
        return {

            group: {
				x: 0,
				y: 0,
                height: 30,
                width: 84
            },

            border: {
                x: 0,
                y: 7,
                width: 0,
                height: 0.25,
                fill: '#cccccc'
            },

            text: this.$createItem({
                x: 0,
                y: 0,
				text: '',
				fill: '#183353',
				fontSize: 20,
				fontStyle: 'bold',
            })

        }
    
    },

    created: function() {
    
        this.border.width = this.config.width
        this.group.x = this.config.x - 7.5
        this.group.y = this.config.y 
        this.text.x = this.config.x
        this.text.text = this.config.text
        this.text.fill = '#183353'

    },

    methods: {

        onClick: function(e) {
        
            this.$emit('click', e)
            this.onMouseLeave()

        },
    
        onMouseEnter: function() {

            this.text.fill = '#FDAA2A'
            this.node('rect').getStage().container().style.cursor = 'pointer'
        
        },

        onMouseLeave: function() {
        
            this.text.fill = '#183353'
            this.node('rect').getStage().container().style.cursor = 'inherit'

        }
    
    }

}

</script>

<style scoped>

</style>
