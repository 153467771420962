<template>

<v-text ref="self" :config="item" v-on:click="$emit('click', $event)" />

</template>

<script>

import mixItem from '@/mixin/item'

export default {

    name: 'AppText',

    mixins: [mixItem],

    props: ['text'],

    data: function() {
    
        return {
            defaultConfig: {
                paddingX: 0,
                paddingY: 0,
                fontSize: 12,
                text: ''
            }
        }
    
    },

    computed: {
    
        configuration: function() {
        
            var config = this.getMergedConfig()

            if (this.fixed) {
            
                config.fontSize = config.fontSize / this.$store.getters['map/scale']
            
            }

            if (this.anchor === 'leftcenter') {
            
                if (this.fixed) config.x = config.x + (config.paddingX / this.$store.getters['map/scale'])
                
                config.y = config.y + (config.paddingY / this.$store.getters['map/scale']) - (config.fontSize / 2)

            }

            if(this.text) config.text = this.text

            config.duration = 0

            return config
        
        }

    }

}

</script>

<style scoped>

</style>
