<template>

<div id="app" :class="{'is-small': window.width < 1200}">

	<com-pdf />

	<com-sidebar />

	<com-map />

	<com-toggle />

	<com-key />

	<com-prompt />

</div>

</template>

<script>

import comPDF from './components/Pdf'
import comMap from './components/Map'
import comSidebar from './components/Sidebar'
import comToggle from './components/Toggle'
import comKey from './components/Key'
import comPrompt from './components/Prompt'

import axios from 'axios'

export default {

	name: 'App',

	components: {
		'com-pdf': comPDF,
		'com-map': comMap,
		'com-sidebar': comSidebar,
		'com-toggle': comToggle,
		'com-key': comKey,
		'com-prompt': comPrompt
	},

	created: function() {

		axios.get('https://proper-excellence-016c779436.strapiapp.com/api/map').then(function(response) {

			this.$store.commit('data', response.data)
		
		}.bind(this))

	}

}

</script>

<style>

#app {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
	user-select: none;
}

</style>
