<template>

<v-group ref="self" :config="item" v-on:tap="$emit('click', $event)" v-on:click="$emit('click', $event)" v-on:mouseenter="$emit('mouseenter')" v-on:mouseleave="$emit('mouseleave')">

    <slot></slot>

</v-group>

</template>

<script>

import mixItem from '@/mixin/item'

export default {

    name: 'AppGroup',

    mixins: [mixItem],

    data: function() {
    
        return {
            defaultConfig: {
                
            }
        }
    
    },

    computed: {
    
        configuration: function() {
        
            var config = this.getMergedConfig()

            if (this.anchor === 'center') {
            
                config.x = config.x - (config.width / 2)
                config.y = config.y - (config.height / 2)
            
            }
            
            return config
        
        }

    }

}

</script>

<style scoped>

</style>
