<template>

<v-circle ref="self" :config="item" v-on:click="$emit('click', $event)" />

</template>

<script>

import mixItem from '@/mixin/item'

export default {

    name: 'AppCircle',

    mixins: [mixItem],

    data: function() {
    
        return {
            defaultConfig: {
                radius: 0
            }
        }
    
    },

    computed: {
    
        configuration: function() {
        
            var config = this.getMergedConfig()

            return config
        
        }

    }

}

</script>

<style scoped>

</style>
