export default {

    methods: {

		$createItem: function(config, states) {

			states = states || {}

			config.state = 'default'
			config.states = states

			return config

		},

		$getNode: function(name) {

			return this.$refs[name].getNode()
		
		},

		node: function(name) {

			return this.$getNode(name)
		
		}
    
    }

}