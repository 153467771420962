import Konva from 'konva'

export default {

    methods: {

		zoomTo: function(scale) {

			var stage = this.node('stage')

			var oldScale = stage.scaleX()
			var pointer = stage.getPointerPosition()

			var mousePointTo = {
				x: (pointer.x - stage.x()) / oldScale,
				y: (pointer.y - stage.y()) / oldScale,
			}
		
			var newPos = this._checkPosition({
				x: pointer.x - mousePointTo.x * scale,
				y: pointer.y - mousePointTo.y * scale,
			}, scale)

			this._zoom(scale, newPos)

		},

		zoomByPinch: function(e) {

			e.preventDefault()
		
			var stage = this.node('stage')

			var oldScale = stage.scaleX()
			var pointer = stage.getPointerPosition()

			var mousePointTo = {
				x: (pointer.x - stage.x()) / oldScale,
				y: (pointer.y - stage.y()) / oldScale,
			}

			var newScale = oldScale * e.scale

			if (newScale > this.$constants.scaling.max) newScale = this.$constants.scaling.max
			if (newScale < this.$constants.scaling.min) newScale = this.$constants.scaling.min

			var newPos = this._checkPosition({
				x: pointer.x - mousePointTo.x * newScale,
				y: pointer.y - mousePointTo.y * newScale,
			}, newScale)

			this._zoom(newScale, newPos)
		
		},

		zoomByMouse: function(e) {

			e.preventDefault()
		
			var stage = this.node('stage')

			var oldScale = stage.scaleX()
			var pointer = stage.getPointerPosition()

			var mousePointTo = {
				x: (pointer.x - stage.x()) / oldScale,
				y: (pointer.y - stage.y()) / oldScale,
			}

			let direction = e.deltaY > 0 ? 1 : -1

			if (e.ctrlKey) {
				direction = -direction
			}

			var newScale = direction < 0 ? oldScale * this.$constants.scaling.step : oldScale / this.$constants.scaling.step

			if (newScale > this.$constants.scaling.max) newScale = this.$constants.scaling.max
			if (newScale < this.$constants.scaling.min) newScale = this.$constants.scaling.min

			var newPos = this._checkPosition({
				x: pointer.x - mousePointTo.x * newScale,
				y: pointer.y - mousePointTo.y * newScale,
			}, newScale)

			this._zoom(newScale, newPos)

		},

		_zoom: function(scale, position) {
		
			var stage = this.node('stage')

            console.log('map/zoom', position, scale)
		
			new Konva.Tween({
				node: stage,
				duration: this.$constants.scaling.duration,
				easing: Konva.Easings.EaseOut,
				scaleX: scale,
				scaleY: scale,
				x: position.x,
				y: position.y,
				onUpdate: function() {

					this.$store.commit('map/scale', this.node('stage').getScaleX())
				
				}.bind(this)
			}).play()

		
		}
    
    }

}