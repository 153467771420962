<template>

<v-layer>

    <v-image ref="image" :config="config" />

</v-layer>

</template>

<script>

const image = require('@/assets/map.svg')

export default {

    name: 'MapBackground',

    data: function() {
    
        return {
        
            config: {
                image: new Image(),
                x: this.$constants.map.offsetWidth,
                y: this.$constants.map.offsetHeight,
                width: this.$constants.map.fullWidth,
                height: this.$constants.map.fullHeight
            }
        
        }
    
    },

	created: function() {
	
		this.$nextTick(function() {

			this.config.image.src = image

		}.bind(this))

	}

}

</script>

<style scoped>

</style>
