import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'underscore'

Vue.use(Vuex)

const store = new Vuex.Store({

	state: {
	
		scale: 1,
		mode: 'shipping',
		sidebar: 'menu',
		port: false,
		from: false,
		to: false,
		pdf: false,
		data: {}

	},

	getters: {

		'features': function(state) {
		
			return state.data.features

		},

		'routes': function(state) {
		
			return state.data.routes

		},

		'locations': function(state) {
		
			return state.data.locations
		
		},

		'locations/selected': function(state) {
		
			return (state.port) ? state.data.locations[state.port] : false
		
		},

		'locations/from': function(state) {
		
			return (state.from) ? state.data.locations[state.from] : false
		
		},

		'locations/to': function(state) {
		
			return (state.to) ? state.data.locations[state.to] : false
		
		},

		'locations/ports': function(state) {
		
			return _.where(state.data.locations, {
				type: 'port'
			})
		
		},

		'locations/dots': function(state) {
		
			return _.filter(state.data.locations, function(location) {
			
				return _.contains(['yellow', 'red'], location.type)

			})
		
		},

		'locations/countries': function(state) {
		
			return _.where(state.data.locations, {
				type: 'country'
			})
		
		},
	
		'map/scale': function(state) {
		
			return state.scale
		
		},

		'map/mode': function(state) {
		
			return state.mode
		
		},

		'map/selected': function(state) {
		
			return state.port
		
		},

		'finder/from': function(state) {
		
			return state.from
		
		},

		'finder/to': function(state) {
		
			return state.to
		
		},
		
		'ui/sidebar': function(state) {
		
			return state.sidebar
		
		},
		
		'pdf': function(state) {
		
			return state.pdf
		
		}

	},

	mutations: {

		data: function(state, value) {
		
			Vue.set(state, 'data', value)

		},

		pdf: function(state, value) {
		
			state.pdf = value
		
		},

		'map/scale': function(state, value) {
		
			state.scale = value || 1
		
		},

		'map/mode': function(state, value) {
		
			state.mode = value
		
		},

		'map/selected': function(state, value) {
		
			state.port = value
			state.from = false 
			state.to = false
			state.sidebar = 'menu'
		
		},

		'finder/from': function(state, value) {
		
			if (state.from) state.to = false
			state.from = value
			state.port = false
		
		},

		'finder/to': function(state, value) {
		
			state.to = value
			state.port = false
		
		},

		'ui/sidebar': function(state, value) {
		
			state.sidebar = value
		
		}
	
	}

})

export default store