<template>

<v-group>

    <v-path ref="path" :config="config" v-if="isPath" />

    <v-line ref="path" :config="config" :points="points" v-if="!isPath" />

</v-group>

</template>

<script>

import Konva from 'konva'

import mixMap from '@/mixin/map'

export default {

    name: 'MapRoute',

    mixins: [mixMap],

    props: ['route', 'active'],

    data: function() {
    
        return {
            config: {
                data: '',
                stroke: '#123455',
                strokeWidth: 0.5,
                opacity: 1,
                x: 0,
                y: 0,
                lineCap: 'round'
            }
        }
    
    },

    created: function() {

        this.config.data = this.route.data

        if (this.route.type === 'red') {
        
            this.config.stroke = '#AD2729'
        
        } else if (this.route.type === 'yellow') {
        
            this.config.stroke = '#FCAA27'
        
        }

        this.config.strokeWidth = 0.5 - ((this.$store.getters['map/scale'] - 1) * 0.15)
    
    },

    computed: {

        points: function() {
        
            var points = this.config.data.split(' ')

            return this.$_.map(points, function(point) {
            
                return parseFloat(point)
            
            })
        
        },
        
        isPath: function() {
        
            return (this.config.data) ? this.config.data[0] === 'M' : true
        
        },

        isHidden: function() {

            return this.$store.getters['ui/sidebar'] === 'routes' || ((this.$store.getters['finder/from'] || this.$store.getters['finder/to']) && !this.$_.contains(this.activeRoutes, this.route.id))

        },

        activeRoutes: function() {
        
            var activeRoutes = []
            
            this.$_.each(this.$store.getters['routes'], function(route) {
            
                if(this.$_.contains(route.locations, this.$store.getters['map/selected']) || (this.$_.contains(route.locations, this.$store.getters['finder/from']) && this.$_.contains(route.locations, this.$store.getters['finder/to']))) {
                
                    if(route.via) {
                    
                        this.$_.each(route.via, function(id) {
                        
                            activeRoutes.push(id)    
                        
                        })
                    
                    } else {

                        activeRoutes.push(route.id)    
                        
                    }
                
                } 

            }.bind(this))

            return activeRoutes
        
        },
    
        isActive: function() {

            if(!this.$store.getters['map/selected']) return true

            return this.$_.contains(this.activeRoutes, this.route.id)
        
        },

        scale: function() {
        
            return this.$store.getters['map/scale']
        
        }

    },
    
    watch: {

        scale: function() {
        
            this.config.strokeWidth = 0.5 - ((this.$store.getters['map/scale'] - 1) * 0.15)
        
        },
    
        isActive: function(n) {
        
            if(n) this.activate()
            if(!n) this.deactivate()
        
        },

        isHidden: function(n) {
        
            this.config.opacity = (n) ? 0 : 1
        
        }

    },

    methods: {

        activate: function() {
        
            new Konva.Tween({
				node: this.node('path'),
				duration: 0.25,
				easing: Konva.Easings.Linear,
				stroke: this.config.stroke 
			}).play()
        
        },

        deactivate: function() {
        
            new Konva.Tween({
				node: this.node('path'),
				duration: 0.25,
				easing: Konva.Easings.Linear,
				stroke: '#cccccc'
			}).play()
        
        }
    
    }

}

</script>

<style scoped>

</style>
