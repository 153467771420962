export default {
    
    map: {
        width: 2048, //2357.3,
        height: 1935.46, //2227.76
        fullWidth: 2357.3,
        fullHeight: 2227.76,
        ratio: 0.868792,
        offsetWidth: -154.65,
        offsetHeight: -146.15
    },

    center: {
        x: 0,
        y: 1500
    },

    scaling: {
		step: 1.5,
		duration: 0.5,
		min: 1,
		max: 3
	},

	panning: {
		duration: 0.5
	}

}