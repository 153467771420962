<template>

<div class="toggle">

	<div class="toggle-title">Routes</div>

    <div class="toggle-options">

        <div class="toggle-options-item is-shipping" :class="{'is-active': value === 'shipping'}" v-on:click="onToggleClick('shipping')"><span>Shipping</span></div>

        <div class="toggle-options-item is-multi" :class="{'is-active': value === 'multi'}" v-on:click="onToggleClick('multi')"><span>Multi-modal</span></div>
    
    </div>

</div>

</template>

<script>

export default {

	name: 'ComToggle',

    computed: {
    
        value: function() {
        
            return this.$store.getters['map/mode']
        
        }
    
    },

    methods: {
    
        onToggleClick: function(value) {
        
            this.$store.commit('map/mode', value)

        }
    
    }
    
}

</script>

<style scoped>

.toggle {
	z-index: 2;
    position: absolute;
    left: 320px;
    top: 20px;
}

.toggle-title {
    color: #183353;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: light;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAWCAYAAADNX8xBAAAABHNCSVQICAgIfAhkiAAAAlxJREFUOE+NVD1vE0EQ3dnDkNDYdA4U+B9wiqEmNRLiiJMCCYnzL8C0NDk3tDh/gLgHB/MPnBocLj8gwhQQI5qkCgjfLm/ON876Yh9YWlm3H2/ezHszpAp+1btbG8raPVwZqSRpjuP+aNl1WnYAkAggOzg/wypbpU49pZrfh73+ojeXgG7d2/YnxuzhwMf6cC1Jwj+lUs0Y0wXYHaz+KtiN4v6pCzgHtLa+2bJEr5kFWRudHO535HLNDyq/PS8C0HNmR0SPx5/eDeQ8Bar6QU15HtdiAxtHNkmCZfXI6sbplRG0szqZtJkd3aw3gkQpTqWiiNqIEhUJwGcZO071EVZcuqq3qVpvcK7l/wVxg1TXNwd4d5/IviIu7r8KmWeYF+TGuX46K/ZavdGRQhbJPGcLa1vjw/0uB5pTLSskH9x2C5kXBP46UMaEriAEJrFR6ot4gwv5y/NY9mepo4maZIxviHZYENjihdhCUvQUvWGgNCV5JN5gNRGA2ZUz6kda6/Dbx7cxf7PnMnBSJnky9dG0py55Q2TmICfDXqvIc7Ma5b1xReumRBfVijxHaVtoHUtK8EaIunCNymAZQZU2BzmH8xE1wP5XT+tAgvB9UvqYDTlileTRjL7WXTYbvgeoITdwBWsXTRxxS7jgVtmXVJSS28QADVwh8m11Ychp53Of8exp/Rj2doXdCrpdWMAaPB1CThHgoYDPG5KngJOSOxWdaVlzUxQhFk5IaYN07sCAmD1+5rUzDTaLpuTSUes2c2bIdFrmJ2MhowvfPLxuaOWBSiY/x5/fH8j+ov+/3CR9rldcJckAAAAASUVORK5CYII=');
    background-size: 9px 11px;
    background-repeat: no-repeat;
    background-position: 0px 50%;
    padding-left: 16px;
}

.toggle-options {
    display: flex;
    justify-content: space-between;
    background-color: #183353;
    border-radius: 4px;
    width: 104px;
    padding: 1px;
}

.toggle-options-item {
    width: 44px;
    height: 44px;
    border-radius: 4px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.toggle-options-item:not(.is-active):hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.toggle-options-item.is-active {
    background-color: #fff;
}

.toggle-options-item span {
    display: block;
    position: absolute;
    bottom: -16px;
    color: #183353;
    font-size: 8px;
    left: 50%;
    transform: translateX(-50%);
    letter-spacing: 0.4px;
    font-weight: light;
    text-transform: uppercase;
    white-space: nowrap;
}

.toggle-options-item.is-shipping {
    background-size: 35px 30px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAA8CAMAAADsSTwRAAAAS1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////+DmQsHAAAAGHRSTlMAP8CAhmgg8A+g4NBfkBpPMHCwj68GM6xh5S6HAAAB1UlEQVRYw+2W2XKcMBBFaXXSQhvbjJP7/18aEaPIowhbMObJPg9UqWmOWluh7n0Ir3DXwvUaFZ/8xTVB1XCbxqoa4X+NQpVNU0fVNHR0wBdr5Gc79I4G7USNHnc0P9qJ2X1X1xzlSs0p6FM018yEok/RACdEdEepidi5P+DovQOwyKPGBR2j97FRMpo13UgRhtr02vi+wZGLLzWRKVhEHNOuilghog3lWKnJJljDIz0KiI3b3qWxh6muWRFvLDas2kgBvczydsGniiYjsetiX2u18CjVfVNqSiaK8EiRFGrW1PnWfGuu0zg6gys1Jyk0fIYALN3zGLj+ecsMLZUw0yHLVP9lethDJTqEnU1gDlgYrqsiGu3DEuwm84G9fI9D2sPCN99C9f5Eetjmw8Tx+Ww5lFa1vZz2YjK2abEkz0wd33TYDMIHlx8N+VhTTaLHjuZOPEfGvuxiZL6xl84/7i9aE8VBc/5ixHLHSnmZEpPC6jd8drOGm6KLF0DfJKlVbJmZiM0qmtJxXlsLE81BAy5phNcWU+7IKmZWGrm2nteWuvFN2VWZSvb2NczKFSVLSBcj9/ZG189uC1uWLuP/hbdyM/0w/BqGl67gZRgkhzN/s3OPfwDr+jyKiqNTQQAAAABJRU5ErkJggg==');
}

.toggle-options-item.is-multi {
    background-size: 21.5px 30px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAA8CAMAAADIULPRAAAAeFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////GqOSsAAAAJ3RSTlMAP7/AgNZD+NEQ8C+PYKCwz82cbxbfiFDgN8cgrnhppJZZT+O5TAdQMkrTAAAB7ElEQVRIx+2WaXKkMAxGJduhY1YDoSG9ZZnlu/8Nx4YeA8ViDpD3o6lqP0qykDA0wDdsc2Oa0GKfdlSTGEVCWyQFYr+qDSTtIWE0DQh0etfVHW7Uk/oQS8YklbtmQBZQe6ntb0kDog/eoCDKMcWFU5iSu/1dCNCUIeAiI42TdRcF6t1FGX/cbTe5Dpck7I4EXGalhBAxYiFuSjHP1npX87dKhcAaQij1zbrXAQNPLAuleEApKTFixJng6KxTczKGY79TrlUhO1hOpDK+0ow2BtC0NOPK1l2QuVSsni3r8Di/0RQdI7e/OWK9UrO5m6EjR4cs6Krn+BVQQbeGfLZFHXQTgIc33DXoUgrkKodNJezq/DnmS/fNqx5WUiW04oYZ3VwcxLk4yskVhw9CGmA6ipk9TK4qW+LqTFRV79OeVPViuhkv1oV1G0zdFHm/igNuM7RdDA66CaB99wXyTVE8u7vRtI+O/3d+h6bmPeoGZjy9QhjtQ9SFlLIsSznD/1fUy5zqlWPtsn7alaubetCcKIoeZXmPlnyW5Z8omnb+C/YpRvUOx+s6cHzRhFecaZ0P3GjGHb/+brjJ9HPnnbk15sxbfBgTMQ/jLnCEamidnpdtYPGT9ht54Dvqk558AdddN4Lv+8tF0T75xbXFP9UMX+Ua6XRuAAAAAElFTkSuQmCC');
}

.toggle-options-item.is-shipping.is-active {
    background-size: 35px 30px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAA8CAMAAADsSTwRAAAAilBMVEUAAAAZMlQZNFQYNVUYNFSvKCquJyqvJiivJygZM1QYMFggMFAYMlWvJyexJyevIS6uJykZM1SuJikZM1MZM1QaM1MZM1QaM1QZM1OuJyiuJymvJymuJykaM1OvJikbNVUZMlStJymvKCiuJymqKiquJyqAKzZlLD8sMk4xMU0ZM1WTKDAZM1SuJylw6P8LAAAALHRSTlMAwIA/QIZofyHwIBBgQBoP8NCgkOBPsKDf0L+P4J8xMHBwX7AGT8/DuKxvWgXNqW8AAAIASURBVFjD7ZbrlpsgEIAFbRG8bIh3jTHJ7vY6vv/rdUylNC6maNZf3e8H5zAMHwPiUec+HvyGOjZsryHY0v9ckxATctSUxtHkrYaAkVFjhpg03tINb6zxP9sT3dH09qCGZzOaT/ZEjggcs2YpW2pW4b2LZpuTIN67aABWiLwcphqk/BEscAShBIBc3GpkwodoZinJ2iG9EJMwkFHP2zCwc6QnTHyrQbokBURSd1blUgIIL9ShGDTaBGVLM/dW4NJWwkBaqL0n3YwGEWGRwkhJRlSA5yfx9wPvTBqt8mgxudec5DQTxntj1mg6D6EZNirdXmPmQ/Oh2U4j3TXIqWYlEw1dQwKQO4/Tggwet5yAC0OYeossnfmTGUK5qEQJycwlKBZYKEjzgOBgvy0Bs8l0wV3OcUtzpBBa/4VydZDGU7Z+mSi2j5bjQYrF2JSzvhgktXpYQp+MmdDqZSsguZ8QcBD/1hiTotuFTo5/Ycjr0yTv6ZWxhl18LJnczB8S/arfMT0jg2/P/cjedzT+XoXr7xBqN9v11RFdDKftGl+pv2Jvf44ith9ExzF8HHrPLIrOza7vv/xUboa9ikV6oUONG6kxirWphYZe3bCmPgxKVfLlcA1jdjUp2W9w6Ep1VtmD6FyN4QPT2Sj6E1bl6ilxfIzjF2fCSxz7Oqy5ZusVfwGR6218wDDCvQAAAABJRU5ErkJggg==');
}

.toggle-options-item.is-multi.is-active {
    background-size: 21.5px 30px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAA8CAMAAADIULPRAAAAyVBMVEUAAAAYM1QYMlIYM1MXMVQYM1OtKCoYM1OuJykYM1MYM1MaNFUgMFAXM1OuJigYM1OuJimvJykWMFAYMlKvJykXM1MYMlIYM1OuJykYM1OuJyqvKCisJSmvJCqvIi2uJyk9L0iuJymuJimCKzZ9KzavJiiuJyoYM1MYMFCvKCiuJymvJylqLDutJykyMUuuJyk8L0itJykXM1IYM1SvJyl2LDoZM1MYM1QZM1NcLEGuJymtKCquJikYM1NNL0WvJyqSJCQYM1OuJym/9Ox6AAAAQXRSTlMAP8DWQ/iA0cB/sDAQ8D/gno4WYODPcFCakIdgNy8Q783HoNDOUNegICDwsI9wTtPMy8m9qaSai4d6dW1raWdcB22MlkcAAAIhSURBVEjH7Zb3nqIwEICTLMsRDMVC8TzXfrtuu97rzPs/1A2ghCKEB7jvjwy/yWcSx9HICrgL3bicVUign0SrIw/cEeti5IJXzqoIBOtDQKTKx0D1uioAt3ia6y1a6EPKLE4BpswESUn+knm/pzfPj7LFKjbN2VglzN7Uewag2AINLi6YgmtyWR3n5DqN/H+3210ei7A0uxqDy7mUQggPPCFcKTmvzeWu4omcCwGXEELKhKtiaYigxCtWI4odQBOJLwwyAnJiXna87/tll/NYuiIA4prJKb9jNQ4rRJwd6sk7Tm6LBeK9c4+4aNfh19dJLXWzwi2NW1zdXKjZpLHsLI8zXBhdG8d5fEDb6D6hc2qLJ6O7RPSzYiAejS4bI4Z2iHQUs0slyNiytjshtYFvO/aSXXDNaPdWDCRzYSDkJgB8IEyRy4YSgazWIE2pxOlLxtL0VbUnZUyjBFF18TW5SO4Mq+4cbmnkAAPcoPjx94Ab3RGAyqILsuKGIbkhuWFYceeni2hquLII5cH0fJYg5n3EAUT69jIRqXKL2BVCrNdr0YByb2l0qbbNL/AP1mSMmyy00+9obC/ws5GyLOv3fv/NavO43z9b1ohprqAfV6sSMl5cpvHHhHhDH+tlPuJDPfEdZ3873CXiQfcF58+73SPv4tNuZ3E+yd0PMITPRevkXHVTCPx8/YWsDwfT8+MfxGOv62PZ95uNzfoJN1lb/APdwpotINBh7AAAAABJRU5ErkJggg==');
}

</style>
