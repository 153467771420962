import Konva from 'konva'

export default {

    methods: {

        panTo: function(options) {

            options = options || {}

            this._pan({
				scale: options.scale,
				duration: (options.duration === undefined) ? this.$constants.panning.duration : options.duration,
                x: options.x || 0,
                y: options.y || 0
            })
        
        },

        panCenter: function(animated) {

            this._pan({
				duration: (animated) ? this.$constants.panning.duration : 0,
                x: this.$constants.map.width / 2,
                y: this.$constants.map.height / 2
            })
        
        },

		panByMouse: function(e) {

			e.preventDefault()

			var stage = this.node('stage')

			var newPos = this._checkPosition({
				scaled: true,
				x: stage.position().x + e.deltaX,
				y: stage.position().y + e.deltaY
			}, stage.scaleX())

			this._pan(newPos)
		
		},
    
		_pan: function(options) {

            options = options || {}

			options.scaled = options.scaled || false

			var stage = this.node('stage')
			var scale = options.scale || stage.scaleX()

            options.duration = (options.duration === undefined) ? this.$constants.panning.duration : options.duration

            options.x = options.x || 0
            options.y = options.y || 0
            
            var position = {
                x: (options.scaled) ? options.x : 0 - ((options.x * scale) - (this.window.width / 2)),
                y: (options.scaled) ? options.y : 0 - ((options.y * scale) - (this.window.height / 2))
            } 

            position = this._checkPosition(position, scale)

            console.log('map/pan', options, position, scale)

			new Konva.Tween({
				node: stage,
				duration: options.duration,
				easing: Konva.Easings.EaseOut,
				x: position.x,
				y: position.y,
				scaleX: scale,
				scaleY: scale,
				onUpdate: function() {

					this.$store.commit('map/scale', this.node('stage').getScaleX())
				
				}.bind(this)
			}).play()
        
        },

		_checkPosition: function(value, scale) {

			var stage = this.node('stage')

			var scaledWidth = stage.width() * scale
			var scaledHeight = stage.height() * scale

			if (value.x > 0) value.x = 0
			if (value.x < 0 - (scaledWidth - this.window.width)) value.x = 0 - (scaledWidth - this.window.width)

			if (value.y > 0) value.y = 0
			if (value.y < 0 - (scaledHeight - this.window.height)) value.y = 0 - (scaledHeight - this.window.height)

			return value
		
		}
    
    }

}