<template>

<div class="ports">

	<div class="ports-back" v-on:click="onBackClick">Back</div>

	<div class="ports-country" v-for="country in countries" :key="country.id">

		<template v-if="cities[country.id].length">

			<div class="ports-country-name" v-on:click="onLocationClick(country.id)">{{ country.label }}</div>

			<div class="ports-country-city" :data-flag="country.code" v-for="city in cities[country.id]" :key="city.id" v-on:click="onLocationClick(city.id)">{{ city.name }}</div>

		</template>

	</div>

</div>

</template>

<script>

export default {

	name: 'ComPorts',

	computed: {
	
		countries: function() {
		
			return this.$_.sortBy(this.$store.getters['locations/countries'], function(country) {
			
				return country.label

			})
		
		},

		cities: function() {
		
			var cities = {}

			this.$_.each(this.countries, function(country) {
			
				cities[country.id] = []

				this.$_.each(this.$_.where(this.$store.getters['locations/ports'], {
					country: country.id
				}), function(location) {

					if (this.$store.getters['ui/sidebar'] === 'finder.to' && this.$store.getters['finder/from']) {
					
						if (!this.$_.contains(this.$store.getters['locations/from'].connected, location.id)) return
					
					} else if (this.$store.getters['ui/sidebar'] === 'finder.from' && this.$store.getters['finder/to'] && !this.$store.getters['finder/from']) {

						if (!this.$_.contains(this.$store.getters['locations/to'].connected, location.id)) return
						
					}
					
					cities[country.id].push({
						id: location.id,
						name: location.label
					})
						
				}.bind(this))

				cities[country.id] = this.$_.sortBy(cities[country.id], function(city) {
			
					return city.label

				})
			
			}.bind(this))

			return cities
		
		}
	
	},

	methods: {

		onBackClick: function() {

			if (this.$store.getters['map/selected']) {
			
				this.$store.commit('ui/sidebar', 'info')

			} else if (this.$store.getters['ui/sidebar'] === 'finder.from') {

				this.$store.commit('ui/sidebar', 'finder')

			} else if (this.$store.getters['ui/sidebar'] === 'finder.to') {

				this.$store.commit('ui/sidebar', 'finder')

			} else {

				this.$store.commit('ui/sidebar', 'menu')

			}

		},
	
		onLocationClick: function(value) {

			if (this.$store.getters['ui/sidebar'] === 'finder.from') {
			
				this.$store.commit('finder/from', value)
				this.$store.commit('ui/sidebar', 'finder')
			
			} else if (this.$store.getters['ui/sidebar'] === 'finder.to') {
			
				this.$store.commit('finder/to', value)
				this.$store.commit('ui/sidebar', 'finder')
			
			} else {
			
				this.$store.commit('map/selected', value)
				this.$store.commit('ui/sidebar', 'menu')

			}
		
		}
	
	}

}

</script>

<style scoped>

.ports {
	padding: 0px 56px;
}

.ports-back {
	color: #fff;
	font-size: 12px;
	letter-spacing: 1.2px;
	color: #fff;
	font-weight: light;
	padding: 20px;
	cursor: pointer;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAbBAMAAAC+faPKAAAAMFBMVEUAAAD///////////////////////////////////////////////////////////87TQQwAAAAD3RSTlMAEM/wMGCf38BQIOCvkEAOB/7hAAAAXUlEQVQI12MAA5YyCO38A0wx1iuCaaHPC8BcfRMo1wEr9yeE+z8Awv2KzGXoh3AZ9ptArYDoYmCxN4QKfBKACihCBOZDBVj/QwXi/zBABRKgAt/QBf5CBT4xQMArAO26HeNtdD2UAAAAAElFTkSuQmCC');
	background-position: 0px 50%;
	background-repeat: no-repeat;
	background-size: 7px 13px;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.ports-country-name {
	color: #FDAA2A;
	letter-spacing: 1px;
	font-weight: light;
	font-size: 10px;
	padding: 20px;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	cursor: pointer;
}

.ports-country-name:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.ports-country-city {
	color: #fff;
	font-weight: light;
	font-size: 14px;
	padding: 20px 20px 20px 65px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	cursor: pointer;
	background-position: 20px 50%;
	background-repeat: no-repeat;
	background-size: 25px 18px;
}

.ports-country-city:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.ports-country-city[data-flag="uk"] {
	background-image: url(~@/assets/flag.uk.png);
}

.ports-country-city[data-flag="se"] {
	background-image: url(~@/assets/flag.se.png);
}

.ports-country-city[data-flag="dk"] {
	background-image: url(~@/assets/flag.dk.png);
}

.ports-country-city[data-flag="be"] {
	background-image: url(~@/assets/flag.be.png);
}

.ports-country-city[data-flag="nl"] {
	background-image: url(~@/assets/flag.nl.png);
}

.ports-country-city[data-flag="ie"] {
	background-image: url(~@/assets/flag.ie.png);
}

.ports-country-city[data-flag="po"] {
	background-image: url(~@/assets/flag.po.png);
}

.ports-country-city[data-flag="es"] {
	background-image: url(~@/assets/flag.es.png);
}

</style>
