<template>

<app-group ref="group" :config="group" v-on:click="$emit('click', $event)">

    <app-circle :config="circle" anchor="center" />

    <app-image :config="image" anchor="center" />

</app-group>

</template>

<script>

import mixMap from '@/mixin/map'

export default {

    name: 'MapPortButton',

    mixins: [mixMap],

    props: ['config'],

    data: function() {
    
        return {

            group: {
                
            },

            circle: {
                radius: 0,
                fill: 0,
            },

            image: {
                src: ''
            }

        }
    
    },

    created: function() {

        this.group = this.$createItem({
            width: this.config.size,
            height: this.config.size
        }, {
            default: {
                x: this.config.states.default.x,
                y: this.config.states.default.y,
                opacity: (this.config.states.default.opacity === undefined) ? 1 : this.config.states.default.opacity,
                easing: this.config.states.default.easing,
                duration: this.config.states.default.duration,
                listening: false
            },
            active: {
                x: this.config.states.active.x,
                y: this.config.states.active.y,
                opacity: (this.config.states.active.opacity === undefined) ? 1 : this.config.states.active.opacity,
                easing: this.config.states.active.easing,
                duration: this.config.states.active.duration,
                listening: true
            },
            selected: {
                x: this.config.states.active.x,
                y: this.config.states.active.y,
                opacity: (this.config.states.active.opacity === undefined) ? 1 : this.config.states.active.opacity,
                easing: this.config.states.active.easing,
                duration: this.config.states.active.duration,
                listening: true
            }
        })

        this.circle = this.$createItem({
            radius: this.config.size / 2,
        }, {
            default: {
                fill: this.config.fill
            },
            active: {
                fill: this.config.fill
            },
            selected: this.config.states.selected 
        })

        this.image = this.$createItem({
            src: this.config.image,
            width: this.config.size,
            height: this.config.size,
            natural: 0.25
        })

    },

    watch: {
    
        config: {
        
            deep: true,

            handler: function(n) {
            
                this.group.state = n.state
                this.circle.state = n.state
            
            }
        
        }
    
    }

}

</script>

<style scoped>

</style>
