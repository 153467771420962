<template>

<div class="prompt" v-if="showPrompt">

	Double-click on any area to navigate through the interactive map.

</div>

</template>

<script>

export default {

    name: 'ComPrompt',

    data: function() {
    
        return {
            first: true
        }
    
    },

    watch: {
    
        scale: function(n) {
        
            if (n > 1) this.first = false
        
        }

    },

    computed: {

        scale: function() {

            return this.$store.getters['map/scale']

        },
    
        showPrompt: function() {
        
            return this.first
        
        }
    
    }
    
}

</script>

<style scoped>

.prompt {
	z-index: 2;
    position: absolute;
    max-width: 804px;
    width: 100%;
    line-height: 68px;
    background-color: rgba(24, 51, 83, 0.6);
    bottom: 60px;
    left: 50%;
    transform: translateX(calc(-50% + 150px));
    color: #fff;
    font-size: 18px;
    font-weight: light;
    border-radius: 5px;
    text-align: center;
    pointer-events: none;
}

</style>
