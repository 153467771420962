<template>

<v-group ref="group" :config="container" v-if="!isHidden">

	<app-circle :config="circle" :fixed="true" v-if="!isHidden" />

	<app-text :config="label" anchor="leftcenter" :fixed="true" v-if="showLabel" />

	<app-image :config="office" anchor="leftcenter" :fixed="true" v-if="dot.office && showLabel && isMulti" />

</v-group>

</template>

<script>

export default {

	name: 'MapDot',

	props: ['dot'],

	data: function() {
	
		return {

			container: {
                x: 0,
                y: 0,
			},

			circle: {
                x: 0,
                y: 0,
                radius: 2,
                fill: ''
            },
		
			office: {
				src: 'office.svg',
				x: 0,
				y: 0,
				width: 37,
				height: 11,
				paddingX: 10,
				paddingY: 15
			},

			label: {
				text: '',
				fill: '#1A3453',
				fontSize: 11,
				x: 3,
				y: 0.3
			}

		}
	
	},

	created: function() {

		this.container.x = this.dot.x
		this.container.y = this.dot.y
		this.label.text = this.dot.label
        this.circle.fill = (this.dot.type === 'red') ? '#AD2729' : '#FCAA27'

	},

	watch: {
	
		isInactive: function(n) {
		
			this.circle.fill = (n) ? '#eeeeee' : ((this.dot.type === 'red') ? '#AD2729' : '#FCAA27')
			this.label.fill = (n) ? '#eeeeee' : '#1A3453'
		
		}

	},

	computed: {

		isMulti: function() {
		
			return this.$store.getters['map/mode'] === 'multi'

		},

		showLabel: function() {
		
			return this.$store.getters['map/scale'] >= 1.5 || !this.isMulti
		
		},

		isInactive: function() {
		
			return this.$store.getters['map/selected']
		
		},
	
		isHidden: function() {
		
			return this.$store.getters['ui/sidebar'] === 'routes' || (this.$store.getters['finder/from'] || this.$store.getters['finder/to'])
		
		}
	
	}

}

</script>

<style scoped>

</style>
