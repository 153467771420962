<template>

<v-image ref="self" :config="item" v-on:tap="$emit('click', $event)" v-on:click="$emit('click', $event)" />

</template>

<script>

import mixItem from '@/mixin/item'

export default {

    name: 'AppImage',

    mixins: [mixItem],

    data: function() {
    
        return {
            defaultConfig: {
                image: new Image()
            },
            naturalWidth: 0,
            naturalHeight: 0
        }
    
    },

    created: function() {
    
        const image = require('@/assets/' + this.config.src)

        this.defaultConfig.image.addEventListener('load', function() {

            this.naturalWidth = this.defaultConfig.image.naturalWidth
            this.naturalHeight = this.defaultConfig.image.naturalHeight
        
        }.bind(this))

		this.$nextTick(function() {

			this.defaultConfig.image.src = image
            
		}.bind(this))
    
    },

    computed: {
    
        configuration: function() {
        
            var config = this.getMergedConfig()

            if (config.natural) {
            
                config.width = this.naturalWidth * config.natural
                config.height = this.naturalHeight * config.natural

            }

            if (this.fixed) {
            
                config.width = config.width / this.$store.getters['map/scale']
                config.height = config.height / this.$store.getters['map/scale']
            
            }

            if (this.anchor === 'center') {
            
                config.x = config.x - (config.width / 2)
                config.y = config.y - (config.height / 2)
            
            } else if (this.anchor === 'leftcenter') {
            
                if (this.fixed) config.x = config.x + (config.paddingX / this.$store.getters['map/scale'])
                config.y = config.y + (config.paddingY / this.$store.getters['map/scale']) - (config.height / 2)
            
            }

            config.duration = 0
            
            return config
        
        }

    }

}

</script>

<style scoped>

</style>
