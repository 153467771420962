import '@/assets/reset.css'

import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'

import VueKonva from 'vue-konva'
import { VueHammer } from 'vue2-hammer'

VueKonva.pixelRatio = 1

import _ from 'underscore'
import constants from './constants'
import pubsub from './pubsub'
import store from './store'

import mixInjection from './mixin/injection'
import mixWindow from './mixin/window'

Vue.config.productionTip = false

Vue.mixin(mixInjection)
Vue.mixin(mixWindow)

Vue.use(VueKonva)
Vue.use(VueHammer)
Vue.use(Vuex)

var requireComponentGlobal = require.context('./components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

new Vue({
	constants,
	pubsub,
	store,
	_,
	render: h => h(App),
	created: function() {

		pubsub.$emit('window.initiated')

	}
}).$mount('#app')
