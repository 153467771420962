<template>

<div class="menu">

	<div class="menu-item is-ports" v-on:click="onItemClick('ports')">Our Ports</div>
	<div class="menu-item is-finder" v-on:click="onItemClick('finder')">Route Finder</div>

</div>

</template>

<script>

export default {

	name: 'ComMenu',

	methods: {
	
		onItemClick: function(value) {
		
			this.$emit('change', value)
		
		}
	
	}

}

</script>

<style scoped>

.menu {
	border-top: 1px solid #354D68;
}

.menu-item {
	border-bottom: 1px solid #354D68;
	padding: 16px 56px 16px 112px;
	color: #fff;
	font-size: 18px;
	line-height: 18px;
	font-weight: light;
	background-repeat: no-repeat;
	background-position: 56px 50%;
	cursor: pointer;
}

.menu-item:hover {
	background-color: #354D68;
}

.menu-item.is-ports {
	background-size: 35px 21px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAAqCAMAAAA5xt2XAAAAUVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////8IN+deAAAAGnRSTlMAQMCA8HAvEB+wkCM0YODkoDsoGlDEuJ+IdCtBuy0AAAHbSURBVEjHnZSJcqswDEVlSzIOmKWQt1T//6EtqhsmAzcMuTNZsJxLfE4mdB/skcD2VoY7UdOaaFhjIYfLUbG2oTUpGq/vLNDVNGwxPa5Uhvs7NfdB9Kn1ZkxvsLk19JyU6TqbRLtcPVQDdl+sCYZq3HumgEKUaqgJCms8HAyFSM3TU2OGaoInf/4NR/HbdzVEf/5DBH2/PseptvZ7GNt6bFFLHVn9OMfjmt5fW58Cxr47v67JblQFlNQ5D4RrPAP7VYMQ+yROZzXTOmgMIWbZ0KCaDY4oqGnjhgbX+OGxKh84GlxTM+grVaKO5rxmukFVDq2iybwmDvyUf+Yv+RuOQFW+XtEEizDrvZIlrErF0WxfH/+biPoTFhWnn5oPWYrkIl2SeZbUSclSFvn4qbndsCpfFq01thTLxbpk82yps5KtLFZrVLAqUT91rRmXMuYydmmc5zF1Y8ljWUavqdtUoCgfnbMhUaDKV/3Q56Z8H1Cl8qCf+UXyY7MpEOVnPo9vBKpiC6hBH1MEA0dzngqHBYgCP0xAcq/K1wAaCCdbOKr3xxU4pgeiEBoMJ7YHogAaCOdQlTBCg+GwHIhCaDCcvapgGaHBcPaq1JgBGgiHeaeqNTOABsL5zq+qL+yjJs0VfWrMAAAAAElFTkSuQmCC');
}

.menu-item.is-finder {
	background-size: 26px 27px;
	background-position: 62px 50%;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA2CAMAAAC/bkrSAAAAV1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////+ORg7oAAAAHHRSTlMAH/A/g5z2Hs+gV9zAYBAwcOev4NC/ULCQj28JC2HA4wAAAgxJREFUSMelVe22oyAMhKAsCmi1trV35/2fc2+llPKheM7Oj/YYGJxJYmAx+nmS+IWc5p6dAr/hBfoFXrjxcxTd+MdGn6BdALRxqAVwOeLcgDmPGuC2z9GQReO9hN7n0Ef9KKwV48cp7bFmEHMQzys2XJ/iHSK0RQ0Ad8d2wLJaIey6AN07CJSU++id3Ea39wG6+zNLKTLbvwWJ77ggWLchTyx/i7tjGeKVYcH9vSMltc4oJ/KcwCLifksM6Y55YMyVj+icaxnHhauDcMspOghXR1FS1zljueGupE+73fRgRRhy3LgtCE7dZaf5nTBQFAWFtQIEbDg6ITXBUmqqKZEmR2I7CKT/fRPJM55knAgFXs8eh4qiDZp6nVo0ieip3hFTuqrBa73HoTPVptblJncMVL4nDslStDDuy1UJiytXCFOaRwA/mBHha0+ybvw0msM0mv0ZBg0rgCA+c089f4T4earP3BMgVp6Wk5e0+gm7+mRO2LnelFcQZnnQHjoon33nV8J5U3lhChpqi/XDgox6NGtBKtXiwg6hMOeXnWIVyLQgfdKohxdiMCRYFW2wFV22NVvm+8EbsogxpLba8NpgqMPSfOFvVpe+ZHChsZYMzw4YrtdhhxFUcaczYKTDihno11gzSdiiO2JpKAWdhVfY484AbR9jDIWjZHAp+aYzBR11/DBsv38y+Az+AxS/J92yoC79AAAAAElFTkSuQmCC');
}

</style>
