<template>

<v-group ref="group" :config="container">

	<app-circle :config="innerCircle" />
	<app-circle :config="outerCircle" />

	<app-group :config="routeGroup">

		<app-text :config="routeTitle" :fixed="true" anchor="leftcenter" />

		<com-route v-for="(route, index) in routes" :key="route.id" :id="route.id" :config="routeTemplates[index]" v-on:click="onRouteClick(route.id)" />

	</app-group>

	<app-text :config="label" anchor="leftcenter" :fixed="true" v-on:click="onLocationClick" v-if="showLabel" />

	<app-image :config="office" anchor="leftcenter" :fixed="true" v-if="port.office && showLabel && isMulti" />

	<com-button :config="button.close" v-on:click="onCloseClick" />
	<com-button :config="button.routes" v-on:click="onRoutesClick" />
	<com-button :config="button.info" v-on:click="onInfoClick" />

	<app-image :config="location" anchor="center" :fixed="true" v-on:click="onLocationClick" />

</v-group>

</template>

<script>

import mixMap from '@/mixin/map'

import comButton from './port/Button'
import comRoute from './port/Route'

export default {

	name: 'MapPort',

	mixins: [mixMap],

	props: ['port'],

	components: {
		'com-button': comButton,
		'com-route': comRoute
	},

	data: function() {
	
		return {

			container: {
				x: 0,
				y: 0,
				width: 84,
				height: 84,
				listening: true,
				opacity: 1
			},

			routeGroup: this.$createItem({
				x: 0,
				y: 0,
				width: 84,
				height: 84
			}, {
				default: {
					duration: 0.1,
					opacity: 0,
					offsetX: 0,
					listening: false
				},
				active: {
					duration: 0.4,
					easing: 'ElasticEaseOut',
					opacity: 1,
					offsetX: 84,
					listening: true
				}
			}),

			routeTitle: this.$createItem({
				text: 'DESTINATIONS',
				letterSpacing: 1.2,
				fontSize: 12,
				fontWeight: 'light',
				fill: '#183353',
				x: 0,
				y: 0
			}),

			routeTemplate: {
				text: '',
				x: 0,
				y: 0,
				border: 0
			},

			innerCircle: this.$createItem({
				x: 42,
				y: 42,
				fill: '#ffffff'
			}, {
				default: {
					radius: 0,
					opacity: 0,
					duration: 0.1
				},
				active: {
					easing: 'ElasticEaseOut',
					radius: 42,
					opacity: 0.8                 
				}
			}),

			outerCircle: this.$createItem({
				x: 42,
				y: 42,
				fill: '#ffffff'
			}, {
				default: {
					radius: 0,
					opacity: 0,
					duration: 0.1
				},
				active: {
					easing: 'ElasticEaseOut',
					radius: 126,
					opacity: 0.5                 
				}
			}),
		
			office: this.$createItem({
				src: 'office.svg',
				x: 42,
				y: 42,
				width: 37,
				height: 11,
				paddingX: 14,
				paddingY: 15
			}),
		
			location: this.$createItem({
				src: 'port.svg',
				x: 42,
				y: 42
			}, {
				default: {
					width: 20,
					height: 14
				},
				active: {
					width: 60,
					height: 42             
				}
			}),

			label: this.$createItem({
				text: '',
				fill: '#1A3453',
				x: 42,
				y: 42
			}, {
				default: {
					paddingY: 1,
					paddingX: 14,
					fontSize: 14,    
					fontStyle: 'bold',
				},
				active: {
					paddingY: 2,
					paddingX: 40,
					fontSize: 54,    
					fontStyle: 'bold'
				},
				connected: {
					y: 48.5,
					x: 38.5,
					paddingY: 0,
					paddingX: 0,
					fontSize: 16,    
					fontStyle: 'bold'
				}
			}),

			button: {

				close: this.$createItem({
					image: 'button.close.svg',
					size: 18,
					fill: '#707070'
				}, {
					default: {
						opacity: 0,
						x: 42,
						y: 42,
						duration: 0.1
					},
					active: {
						easing: 'ElasticEaseOut',
						opacity: 1,
						x: 32,
						y: 0
					}
				}),

				routes: this.$createItem({
					image: 'button.routes.svg',
					size: 18,
					fill: '#183353'
				}, {
					default: {
						opacity: 0,
						x: 42,
						y: 42,
						duration: 0.1
					},
					active: {
						easing: 'ElasticEaseOut',
						opacity: 1,
						x: 10,
						y: 10
					},
					selected: {
						fill: '#FDAA2A'
					}
				}),

				info: this.$createItem({
					image: 'button.info.svg',
					size: 18,
					fill: '#183353'
				}, {
					default: {
						opacity: 0,
						x: 42,
						y: 42,
						duration: 0.1
					},
					active: {
						easing: 'ElasticEaseOut',
						opacity: 1,
						x: 0,
						y: 32
					},
					selected: {
						fill: '#FDAA2A'
					}
				})

			}

		}
	
	},

	created: function() {

		this.container.x = this.port.x - 42
		this.container.y = this.port.y - 42

		this.label.text = this.port.label

	},

	computed: {

		isMulti: function() {
		
			return this.$store.getters['map/mode'] === 'multi'

		},

		showLabel: function() {
		
			return this.$store.getters['map/scale'] >= 1.5 || !this.isMulti
		
		},

		routeTemplates: function() {
		
			var templates = []

			var offsetY = 15
			var offsetYStart = 42

			var offsetX = 1
			var offsetXStart = 7

			var borderStart = 84
			var borderOffset = 7.5

			var centerIndex = Math.floor(this.routes.length / 2)
			
			this.$_.each(this.routes, function(route, index) {
			
				var config = this.$_.extend({}, this.routeTemplate)

				config.text = route.name
				config.width = borderStart + (Math.abs((centerIndex - index)) * borderOffset)
				config.x = offsetXStart + Math.abs((centerIndex - index) * offsetX)
				config.y = (index < centerIndex) ? offsetYStart - Math.abs((centerIndex - index) * offsetY) : offsetYStart + Math.abs((centerIndex - index) * offsetY)

				templates.push(this.$createItem(config))

			}.bind(this))

			return templates
		
		},

		routes: function() {

			var countries = this.$_.filter(this.$store.getters['locations/countries'], function(country) {

				return this.$_.contains(this.port.connected, country.id)
			
			}.bind(this))

			var routes = []

			this.$_.each(countries, function(country) {
			
				routes.push({
					id: country.id,
					name: country.label
				})

			}.bind(this))

			routes = this.$_.sortBy(routes, function(route) {
			
				return route.name
			
			})

			return routes

		},
	
		isViewingInfo: function() {
		
			return this.isSelectedLocation && (this.$store.getters['ui/sidebar'] === 'info' || this.$store.getters['ui/sidebar'] === 'ports')
		
		},
	
		isViewingRoutes: function() {
		
			return this.isSelectedLocation && this.$store.getters['ui/sidebar'] === 'routes'
		
		},
	
		isSelectedLocation: function() {
		
			return this.$store.getters['map/selected'] === this.port.id
		
		},
	
		isLocalLocation: function() {
		
			return this.$_.contains(this.$store.getters['locations/selected'].local, this.port.id) && !this.isSelectedLocation
		
		},

		isConnectedLocation: function() {

			if (!this.$store.getters['map/selected'] || this.$store.getters['ui/sidebar'] === 'routes') return false

			return this.$_.contains(this.$store.getters['locations/selected'].connected, this.port.id)

		},

		isHiddenLocation: function() {
		
			return this.$store.getters['map/selected'] && !this.isSelectedLocation && !this.isConnectedLocation && !this.isLocalLocation

		}

	},

	watch: {
	
		isSelectedLocation: function() {

			console.log('map/port/isSelectedLocation', this.port.id, this.isSelectedLocation)
		
			this.selectedState()
		
		},
	
		isConnectedLocation: function() {

			console.log('map/port/isConnectedLocation', this.port.id, this.isConnectedLocation)
		
			if(!this.isSelectedLocation) this.connectedState()
		
		},
	
		isLocalLocation: function() {

			console.log('map/port/isLocalLocation', this.port.id, this.isLocalLocation)
		
			if(!this.isSelectedLocation) this.localState()
		
		},

		isViewingInfo: function(n) {

			console.log('map/port/isViewingInfo', this.port.id, this.isViewingInfo)

			if(n) this.button.info.state = 'selected'
			if(!n && this.isSelectedLocation) this.button.info.state = 'active'
			if(!n && !this.isSelectedLocation) this.button.info.state = 'default'
		
		},

		isHiddenLocation: function(n) {
		
			this.container.opacity = (n) ? 0 : 1
			this.container.listening = (n) ? false : true

		},
		
		isViewingRoutes: function(n) {

			console.log('map/port/isViewingRoutes', this.port.id, this.isViewingRoutes)

			this.routeTitle.x = this.routeTemplates[0].x + 2.5
			this.routeTitle.y = this.routeTemplates[0].y - 15

			if(n) this.button.routes.state = 'selected'
			if(!n && this.isSelectedLocation) this.button.routes.state = 'active'
			if(!n && !this.isSelectedLocation) this.button.routes.state = 'default'

			if(n) this.routeGroup.state = 'active'
			if(!n) this.routeGroup.state = 'default'

			if(n) this.outerCircle.state = 'active'
			if(!n) this.outerCircle.state = 'default'
		
		}

	},

	methods: {

		onRouteClick: function(id) {

			this.$store.commit('finder/from', this.port.id)
			this.$store.commit('finder/to', id)
			this.$store.commit('ui/sidebar', 'finder')
		
		},

		selectedState: function() {
	
			this.innerCircle.state = (this.isSelectedLocation) ? 'active' : 'default'
			this.location.state =  (this.isSelectedLocation) ? 'active' : 'default'
			this.label.state =  (this.isSelectedLocation) ? 'active' : 'default'
			this.button.close.state =  (this.isSelectedLocation) ? 'active' : 'default'
			this.button.routes.state =  (this.isSelectedLocation) ? 'active' : 'default'
			this.button.info.state = (this.isSelectedLocation) ? 'active' : 'default'

		},

		connectedState: function() {
	
			this.label.state = (this.isConnectedLocation) ? 'connected' : 'default'

		},

		localState: function() {
	
			this.label.state = (this.isLocalLocation) ? 'connected' : 'default'

		},
	
		onRoutesClick: function(e) {
		
			e.evt.stopPropagation()

			this.$store.commit('ui/sidebar', 'routes')

		},
	
		onInfoClick: function(e) {
		
			e.evt.stopPropagation()

			this.$store.commit('ui/sidebar', 'info')

		},
	
		onCloseClick: function(e) {
		
			e.evt.stopPropagation()

			this.$store.commit('map/selected', false)
			this.$store.commit('ui/sidebar', 'menu')
		
		},

		onLocationClick: function(e) {

			e.evt.stopPropagation()

			this.$store.commit('map/selected', this.port.id)
		
		}
	
	}

}

</script>

<style scoped>

</style>
