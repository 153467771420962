<template>

<div class="key">

	<div class="key-item is-shipping">Shipping destinations</div>
	<div class="key-item is-rail" v-if="isMulti">Rail connections</div>
	<div class="key-item is-partner" v-if="isMulti">Partner shipping destinations</div>
	<div class="key-item is-offices" v-if="isMulti">Cargo offices</div>

</div>

</template>

<script>

export default {

    name: 'ComKey',

    computed: {
    
        isMulti: function() {
        
            return this.$store.getters['map/mode'] === 'multi'

        }
    
    }

}

</script>

<style scoped>

.key {
	z-index: 2;
    position: absolute;
    right: 30px;
    bottom: 30px;
    border: 1px solid #DCDDDE;
    background-color: rgba(255, 255, 255, 0.75);
    padding: 10px 20px;
    display: flex;
}

.key-item {
    color: #183353;
    font-size: 8px;
    font-weight: light;
    line-height: 16px;
    letter-spacing: 0.8px;
    padding: 0px 16px 0px 30px;
    text-transform: uppercase;
    background-repeat: no-repeat;
    background-position: 0px 50%;
}

.key-item:last-child {
    padding-right: 0px;
}

.key-item.is-shipping {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAcCAMAAAAkyw3kAAAAllBMVEUTNVWlsb4SNFX///+4Livty8tDXngxT2y8Ojfy2Nfz9fb25eS4w8x/kaNrgJXKYl/78vLg5OnM09qmsr8dPl3pvbzksK/blpXTfHrOb233+Prn6+7X3eLM1NvHz9e8xc+suMOcqriSorGSobHlsbCMnKx8jqFwhZlgd43XiohYcIdXcIdQaYIkRGIVNlfOcG7FVVPBR0XKgyh8AAAAAnRSTlOz3DYerrwAAAEfSURBVDjLjZPpdoMgEIWTzqAQY9Cojdn3fWn7/i/XhkEPBKL9fgnnO3C83Ol8OORZlru7rnhJAdJLu7gBxaZFDOagmQdNYgwG8Vsx7INFP/SLgx4oEsZYpL56A4+YZ6RFJT6RQq2y/FW8puQVHDWfap1ebXELhPjzxgVjZ0Se0NbWEIMhaGR1kpAJaIYBiWYoDLEEl1iJZigjROFoFFSHQtGMEV2Lgvq36Fxd+K9WP3OACoEogWCGd6jjWRjxnMnj3wKIRWAEvgci4ohyx3ZfiKjP3NtPeJvVT0NwCn52ey3FY627M/p5aiX1Z/3w1Ow0re5nZMH05C/ufQUWq/vbUThOoGZybBqucFl5y7BlXOO6MM0ilZMqaIseul3P5i8uzxU+LwP5EQAAAABJRU5ErkJggg==');
    background-size: 20px 14px;
}

.key-item.is-partner:before,
.key-item.is-rail:before {
    content: '';
    display: block;
    width: 20px;
    height: 4px;
    top: 50%;
    position: absolute;
    margin-top: -2px;
    left: 0px;
}

.key-item.is-rail:before {
    background-color: #AD2728;
}

.key-item.is-partner:before {
    background-color: #FCAA27;
}

.key-item.is-offices {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAAAYCAMAAABjozvFAAAAsVBMVEUAAAASNlYSNVYSNFYUNFgQNVUSNVW4Liu3MCgSNVYSNVa6MCoSNVUSNVYSNVe4LSoSNla4Lyu4Liu/MDC5LSq3MCy5LSm4Liu4Liu4LisSNVcQMFARNVW4LyxQMkYTNlYQOFiiLjC4LiwTNVUQNVWRLzRmMUESNVb///+JmqtNaIDx8/VrgZbEzdUwTmvT2uAhQmF5jaB6jqA/W3bi5uqYp7XEzNWntMBcdIuXprVYcYj5iy7jAAAAJ3RSTlMAgMDxQD/CgCDQ4TBv0p9f1tKgEMBAULCQcJAQsK+sUCClb2AwzJ3dxzXRAAACNElEQVQ4y6zSXWvDIBTG8cSkFx1j7HotdLCL83iOVZOmL9v3/2A76hppO7aL9g8xEeGHEpuOiP54/lltm6te6Y669qNdPJJr27elUfKK84NNuUhsHc15nThrB9Ks9ZTimBbOXN80q00hK+cFpUAW28qxTrYArH4DnFWIvrlyWibN+8w5YGRmC1RONzycMjcB7ofbheCcfMbKqVfq1zOXnNLMeWiSOXuE7DI3CpwIENzMrY0xnT7rtrvhmOOZY+zVLBwFiE+cReIE03h52H6x6tM+62EdMw8AWwROXXBxQiiHncLg5Bj5kqMXY5ZPzze/Qsii5IHDIRROGUHmKO4hJ6JrLo/LelFGm0sXpUReh3JRmNRLs8ja+KWD+40zqzuu8S3Xbr5bH7flCEEgiI54AUWlQFDcKlObzcX//8MMQ9AQ85btB48zTXfBU+uqG5TN0DXl0BC7odmJNJeRDbIjJutkqmOkewsMqgJYFYnmTqSZRQKyIEYrI+IUhtu6H+u2r4ljX+9EmtvIGjkSk3XyUtcV/1F1h7yOpV/BjQpUGxcI6c0MSW/vZ0QZL84pz3ZHnbSz4wbAcDdrAU7Pzvh0/MeTvHezlZBJ6pgFdtQtkj4ybJ0BK5B6utQJGz5LGrMs1X3rFVD85VMFfrAljsk9HivJ12mfZRm7wSEBEO4kEcrDlN0O8mMu3S7PZhvNZ7sC+E1xrJH6oayCq1arHlrkuwmz2/prt3IZ6Lia4kihiyT5f2fhC1aQjvyA6PUkAAAAAElFTkSuQmCC');
    background-size: 39px 12px;
    padding-left: 49px;
}

</style>
