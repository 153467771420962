import { render, staticRenderFns } from "./Port.vue?vue&type=template&id=3e974cca&scoped=true"
import script from "./Port.vue?vue&type=script&lang=js"
export * from "./Port.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e974cca",
  null
  
)

export default component.exports