import Konva from 'konva'
import Vue from 'vue'

export default {
    
    props: ['config', 'anchor', 'fixed'],

    data: function() {
    
        return {
            is: {
                initialised: false
            },
            tweenOptions: ['x', 'y', 'width', 'height', 'fontSize', 'radius', 'opacity', 'fill', 'fontStyle', 'fillPatternImage', 'fillPatternRepeat', 'offsetX', 'offsetY'],
            item: {},
            defaultConfig: {
                x: 0,
                y: 0,
                state: 'default',
                states: {
                    default: {}
                }
            }
        }
    
    },

    created: function() {

        Vue.set(this, 'item', this.configuration)
    
        this.$nextTick(function() {
        
            this.is.initialised = true

        }.bind(this))
    
    },

    watch: {
    
        configuration: function(n) {

            if (this.is.initialised && (n.duration === undefined || n.duration !== 0)) {

                var tween = {
                    node: this.self,
                    duration: n.duration || 0.3,
                    easing: Konva.Easings[n.easing || 'Linear']
                }

                this.$_.each(this.tweenOptions, function(field) {
                
                    if (n[field] !== undefined) tween[field] = n[field]
                
                })

                if (n.listening !== undefined) Vue.set(this.item, 'listening', n.listening)

                new Konva.Tween(tween).play()
            
            } else {

                Vue.set(this, 'item', n)

            }
        
        }

    },

    computed: {
    
		self: function() {

			return (this.$refs.self) ? this.$refs.self.getNode() : false
		
		}
    
    },

    methods: {
    
        getMergedConfig: function() {
        
            var merged = this.$_.extend({}, this.defaultConfig, this.config)

            this.$_.each(merged.states[merged.state], function(value, key) {

                merged[key] = value

            })

            delete merged['states']
            delete merged['state']

            return merged

        }
    
    }

}