<template>

<div class="pdf" v-if="src">

    <div class="pdf-head">Route Information <div class="pdf-close" v-on:click="onCloseClick"></div></div>

	<iframe :src="src" width="100%" height="100%" class="pdf-iframe" />

</div>

</template>

<script>

export default {

    name: 'ComPDF',

    computed: {
    
        src: function() {
        
            return (this.$store.getters['pdf']) ? this.$store.getters['pdf'] + '#toolbar=0' : false
        
        }
    
    },

    methods: {
    
        onCloseClick: function() {
        
            this.$store.commit('pdf', false)
        
        }

    }

}

</script>

<style scoped>

.pdf {
	z-index: 200;
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #fff;
}

.pdf-head {
    position: absolute;
    height: 64px;
    left: 0px;
    top: 0px;
    background-color: #183353;
    line-height: 64px;
    color: #fff;
    padding: 0px 20px;
	font-size: 18px;
	font-weight: light;
    width: 100%;
}

.pdf-close {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 64px;
    height: 64px;
	cursor: pointer;
    background-position: 50% 50%;
    background-repeat: no-repeat;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAxCAMAAACrgNoQAAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFHRSTlMA8DBgENCgn5DgcL9ACgfAr4BQIN7oQdQAAADcSURBVEjHjdVJDoMwEETRMjYJkDnp+581KEL5y6peMfyHBJaMelsnZTNdapNaVcvE2NO6a61QjLl+4F0I33dJHWH7RUIE/UnKBH0m6ENBz2yIqJdOhzC9EfTzkBG2R6Q9Iu0RtmceCN+T7IKTld4IDjNBn4krfSaKPhT0fj6NPptnsQDJnKsQWY+I+1dDJH3XhIh6HeJs+u3fZ6LTI9IekfaItEeEPWJJeoS/7u9w1Qnfc9f2zJX3S3q+SNgjjg2O3gk2OHovLqxkIiR+YJG4aeH5gZgljaF0bkP6AvrWIZLqVnYrAAAAAElFTkSuQmCC');
	background-size: 24px 24px;
}

.pdf-iframe {
    position: absolute;
    left: 0px;
    top: 64px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
}

</style>
