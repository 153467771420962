<template>

<div class="contact">

	<div class="contact-back" v-on:click="onBackClick">Back</div>

	<div class="contact-title">Send us a message</div>

	<p v-if="is.submitted" class="contact-message">Thank you, we have received your message and will be in touch.</p>

	<template v-if="!is.submitted">

	<app-input type="textbox" v-model="model.Company" label="Company name" />

	<app-input type="textbox" v-model="model.Phone" label="Phone" placeholder="Please enter prefix and phone number" />

	<app-input type="textbox" v-model="model.Email" label="Email" />

	<app-input type="textarea" v-model="model.Message" label="Message" />

	<app-input type="checkbox" v-model="privacy" :label="privacyLabel" />

	<div class="contact-grow" />

	<div class="contact-button" :class="{'is-disabled': !isValid}" v-on:click="onSubmitClick">Submit</div>

	</template>

</div>

</template>

<script>

import axios from 'axios'

export default {

	name: 'ComContact',

	data: function() {
	
		return {
			is: {
				loading: false,
				submitted: false
			},
			model: {
				Company: '',
				Phone: '',
				Email: '',
				Message: ''
			},
			privacy: false
		}

	},

	computed: {
	
		privacyLabel: function() {
		
			return 'I agree with the <a href="https://www.cldn.com/sites/default/files/_docs/cldn-activities-CARGO-downloads-privacy%20policy.pdf" target="_blank">privacy policy</a>'
		
		},

		isValid: function() {
		
			return this.model.Company && this.model.Phone && this.model.Email && this.model.Message && this.privacy
		
		}
	
	},

	methods: {

		onSubmitClick: function() {

			this.is.loading = true
		
			axios.post('https://proper-excellence-016c779436.strapiapp.com/api/contacts', {
				data: this.model
			}).then(function() {

				this.is.loading = false
				this.is.submitted = true
			
			}.bind(this))

		},
	
		onBackClick: function() {

			this.$store.commit('ui/sidebar', 'finder')

		}
	
	}

}

</script>

<style scoped>

.contact {
	padding: 0px 56px;
	display: flex;
	min-height: calc(100vh - 191px);
	flex-direction: column;
	margin-top: -23px;
}

.contact-back {
	color: #fff;
	font-size: 12px;
	letter-spacing: 1.2px;
	color: #fff;
	font-weight: light;
	padding: 20px;
	cursor: pointer;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAbBAMAAAC+faPKAAAAMFBMVEUAAAD///////////////////////////////////////////////////////////87TQQwAAAAD3RSTlMAEM/wMGCf38BQIOCvkEAOB/7hAAAAXUlEQVQI12MAA5YyCO38A0wx1iuCaaHPC8BcfRMo1wEr9yeE+z8Awv2KzGXoh3AZ9ptArYDoYmCxN4QKfBKACihCBOZDBVj/QwXi/zBABRKgAt/QBf5CBT4xQMArAO26HeNtdD2UAAAAAElFTkSuQmCC');
	background-position: 0px 50%;
	background-repeat: no-repeat;
	background-size: 7px 13px;
	text-transform: uppercase;
	margin-bottom: 23px;
}

.contact-title {
	font-size: 40px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 56px;
}

.contact-message {
	color: #fff;
	font-size: 20px;
	line-height: 24px;
}

.contact-grow {
	flex-grow: 1;
	min-height: 56px;
}

.contact-button {
	line-height: 46px;
	width: 188px;
	text-transform: uppercase;
	color: #183353;
	background-color: #FDAA2A;
	border-radius: 23px;
	font-weight: bold;
	cursor: pointer;
	font-size: 12px;
	letter-spacing: 1.2px;
	text-align: center;
	margin: 0px auto;
}

.contact-button.is-disabled {
	opacity: 0.5;
	pointer-events: none;
}

</style>
