<template>

<div class="finder">

	<div class="finder-back" v-on:click="onBackClick">Back</div>

	<div class="finder-location" v-on:click="onFromClick" :class="{'is-active': from}">{{ fromText }}</div>

	<div class="finder-location" v-on:click="onToClick" :class="{'is-active': to}">{{ toText }}</div>

	<template v-if="isComplete">

		<div class="finder-title is-sailings">Weekly Sailings</div>

		<div class="finder-metric" v-for="destination in destinations" :key="'d' + destination.id">
			<div class="finder-metric-count">{{ destination.sailings }}</div>
			<div class="finder-metric-name">{{ destination.from }} > {{ destination.to }}</div>
			<div class="finder-metric-link" v-if="destination.link" v-on:click.stop="onLinkClick(destination.link)"></div>
		</div>

		<template v-if="alternatives.length">

			<div class="finder-title">Other {{ alternativeText }} sailings</div>

			<div class="finder-metric" v-for="alternative in alternatives" :key="'o' + alternative.id">
				<div class="finder-metric-count">{{ alternative.sailings }}</div>
				<div class="finder-metric-name">{{ alternative.from }} > {{ alternative.to }}</div>
				<div class="finder-metric-link" v-if="alternative.link" v-on:click.stop="onLinkClick(alternative.link)"></div>
			</div>

		</template>

		<div class="finder-grow" />

		<div class="finder-button" v-on:click="onContactClick">Contact us</div>

	</template>

</div>

</template>

<script>

export default {

	name: 'ComFinder',

	computed: {

		destinations: function() {
		
			var fromPorts = (this.isFromCountry) ? this.fromLocation.local : [this.fromLocation.id]
			var toPorts = (this.isToCountry) ? this.toLocation.local : [this.toLocation.id]

			return this.getRoutes(fromPorts, toPorts)

		},

		alternatives: function() {

			if (!this.isBothCountries) {

				var fromPorts = [this.fromLocation.id]
				var toPorts = [this.toLocation.id]
			
				if (this.isFromCountry || this.isToCountry) {
				
					fromPorts = this.fromCountry.local
					toPorts = this.toCountry.local

				}  else {

					toPorts = this.toCountry.local
				
				}

				return this.getRoutes(fromPorts, toPorts, this.$_.pluck(this.destinations, 'id'))

			
			} else {
			
				return []
			
			}

		},

		alternativeText: function() {

			if (!this.isBothCountries) {
			
				if (this.isFromCountry || !this.isToCountry) {
				
					return this.fromText + ' > ' + this.toCountry.label


				} else {
					
					return this.fromCountry.label + ' > ' + this.toText

				} 
			
			} else {
			
				return false
			
			}
		
		},

		isComplete: function() {
		
			return this.from && this.to
		
		},

		isBothCountries: function() {
		
			return this.isToCountry && this.isFromCountry

		},

		isFromCountry: function() {
		
			return this.fromLocation.type === 'country'
		
		},

		isToCountry: function() {
		
			return this.toLocation.type === 'country'
		
		},

		fromLocation: function() {
		
			return this.$store.getters['locations/from']
		
		},

		toLocation: function() {
		
			return this.$store.getters['locations/to']
		
		},

		from: function() {
		
			return this.$store.getters['finder/from']
		
		},

		to: function() {
		
			return this.$store.getters['finder/to']
		
		},

		toCountry: function() {
		
			return (this.isToCountry) ? this.toLocation : this.$store.getters['locations'][this.toLocation.country]
		
		},

		fromCountry: function() {
		
			return (this.isFromCountry) ? this.fromLocation : this.$store.getters['locations'][this.fromLocation.country]
		
		},
	
		fromText: function() {

			return this.from ? this.fromLocation.label : 'Select Departure Port'
		
		},
	
		toText: function() {

			return this.to ? this.toLocation.label : 'Select Arrival Port'
		
		}

	},

	methods: {

		onLinkClick: function(link) {
		
			this.$store.commit('pdf', link)
		
		},

		onContactClick: function() {
		
			this.$store.commit('ui/sidebar', 'contact')
		
		},

		getRoutes: function(fromPorts, toPorts, excludeRoutes) {

			excludeRoutes = excludeRoutes || []

			if (this.$_.isEqual(fromPorts, toPorts)) return []
		
			var routes = this.$_.filter(this.$store.getters['routes'], function(route) {
			
				return this.$_.intersection(route.locations, fromPorts).length && this.$_.intersection(route.locations, toPorts).length && !this.$_.contains(excludeRoutes, route.id)
			
			}.bind(this))

			routes = this.$_.map(routes, function(route) {

				var fromMatch = this.$_.intersection(route.locations, fromPorts)[0]
				var toMatch = this.$_.intersection(route.locations, toPorts)[0]

				return {
					id: route.id,
					sailings: route.sailings,
					from: this.$store.getters['locations'][fromMatch].label,
					to: this.$store.getters['locations'][toMatch].label,
					link: route.link
				}
			
			}.bind(this))

			return routes
		
		},

		onFromClick: function() {
		
			this.$store.commit('ui/sidebar', 'finder.from')
		
		},

		onToClick: function() {
		
			this.$store.commit('ui/sidebar', 'finder.to')
		
		},

		onBackClick: function() {

			this.$store.commit('finder/from', false)
			this.$store.commit('finder/to', false)
			this.$store.commit('ui/sidebar', 'menu')

		}
	
	}

}

</script>

<style scoped>

.finder {
	padding: 0px 56px;
	display: flex;
	min-height: calc(100vh - 191px);
	flex-direction: column;
	margin-top: -23px;
}

.finder-back {
	color: #fff;
	font-size: 12px;
	letter-spacing: 1.2px;
	color: #fff;
	font-weight: light;
	padding: 20px;
	cursor: pointer;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAbBAMAAAC+faPKAAAAMFBMVEUAAAD///////////////////////////////////////////////////////////87TQQwAAAAD3RSTlMAEM/wMGCf38BQIOCvkEAOB/7hAAAAXUlEQVQI12MAA5YyCO38A0wx1iuCaaHPC8BcfRMo1wEr9yeE+z8Awv2KzGXoh3AZ9ptArYDoYmCxN4QKfBKACihCBOZDBVj/QwXi/zBABRKgAt/QBf5CBT4xQMArAO26HeNtdD2UAAAAAElFTkSuQmCC');
	background-position: 0px 50%;
	background-repeat: no-repeat;
	background-size: 7px 13px;
	text-transform: uppercase;
}

.finder-location {
	padding: 30px 0px;
	font-size: 18px;
	cursor: pointer;
	font-weight: light;
	color: rgba(255, 255, 255, 0.5);
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAZBAMAAACFj7BKAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADXRSTlMA0BDwMKBg4CCPv4BQ/NGMNAAAAKtJREFUKM9lzjEKwkAUBNCPqFiLhY0QCy8gwS4gWNiKvRBIbWfrHbyIhTfwChIULeYuLj+OP2GmWmbysmvVyTSj68WK+q7DBm874yV9v8AyjSgVoEyrkF+lpEpNs386/RDI/bAFdu3hiHrth94MTwGeeZAAJBn7gYMgY54PDoI8MgFBAihZ/EGQiX/QAWx8n7IKkvOPTNzKNzB856oBSkAghECJAyUEktveIl+OQFyE2Z9TowAAAABJRU5ErkJggg==');
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 24px 12px;
}

.finder-location:hover,
.finder-location.is-active {
	color: #fff;
}

.finder-location.is-active {
	font-size: 40px;
	font-weight: bold;
}

.finder-title {
	font-size: 12px;
	letter-spacing: 1.2px;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	color: #fff;
	font-weight: 300;
	padding-bottom: 10px;
	margin-top: 40px;
}

.finder-title.is-sailings {
	margin-top: 100px;
}

.finder-metric {
	padding: 10px 14px 10px 16px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	background-position: 0px 50%;
	background-repeat: no-repeat;
	display: flex;
}

.finder-metric-count {
	background-color: #FDAA2A;
	color: #183353;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	width: 26px;
	height: 26px;
	line-height: 26px;
	border-radius: 50%;
	margin-right: 16px;
}

.finder-metric-name {
	font-size: 18px;
	color: #fff;
	font-weight: light;
	line-height: 26px;
}

.finder-metric-link {
	width: 32px;
	height: 32px;
	cursor: pointer;
	right: 10px;
	top: 50%;
	margin-top: -16px;
	position: absolute;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAuCAMAAABzsJvxAAAAZlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+Vn2moAAAAIXRSTlMAwD+LCtqA0CO3EPAwYLDgoJBsRfbs08gspgZ3WUc6IRad6wkPAAAA30lEQVQ4y83Uyw7CIBCFYVoVlEqv3u/n/V9SSyQD7WQaN6b/ggTyLRgWKGUbg3FV6VSc9YjLbCPW9AfZoMq7InIGCDtKA9okDjCKY7lNHJDxTJETGTmZkZMZOZmRI5ZTtWfBVTZiGlE9I+ciVqasyb/pXhCzmmqQRiytFhnl6ixUABfP5DJgGbHXOu3JshuGXTl2HrEjx+x5v4nblSeGcc2GdXuk7RzHSgw7cOx0BFbUR91nMymTNEIIpvv1QeTnnc2k/2XVNLv0S5tLOaBQqsV02n8+cuHyj3Yh1XYf8wa7u0Pw4zAIKwAAAABJRU5ErkJggg==');
	background-position: 100% 50%;
	background-repeat: no-repeat;
	background-size: 19px 23px;
}

.finder-grow {
	flex-grow: 1;
	min-height: 56px;
}

.finder-button {
	line-height: 46px;
	width: 188px;
	text-transform: uppercase;
	color: #183353;
	background-color: #FDAA2A;
	border-radius: 23px;
	font-weight: bold;
	cursor: pointer;
	font-size: 12px;
	letter-spacing: 1.2px;
	text-align: center;
	margin: 0px auto;
}

</style>
